import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'

import { Paragraph, ParagraphComponent } from './types'
import { DrupalNodeTeaser, DrupalMediaImage } from '../../drupal'
import { dimensions } from '../../style/variables'
import Container from '../Container'
import Media from '../fields/Media'

interface ArticleNodeTeaser extends DrupalNodeTeaser {
	__typename: 'node__article'
	fields: {
		summary: string
	}
	relationships: {
		field_media?: DrupalMediaImage
	}
}

interface RecipeNodeTeaser extends DrupalNodeTeaser {
	__typename: 'node__recipe'
	fields: {
		summary: string
	}
	relationships: {
		field_media?: DrupalMediaImage
	}
}

type Content = ArticleNodeTeaser | RecipeNodeTeaser

export interface FeaturedContentParagraph extends Paragraph {
	relationships: {
		field_reference: Content[]
	}
}

const StyledContainer = styled(Container)`
	display: grid;
	align-items: center;
	grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
	grid-gap: ${dimensions.containerPadding}rem;
	align-items: flex-start;

	.content {
		height: 100%;
		border: 1px solid #ced4da;
	}

	.content-body {
		padding: ${dimensions.containerPadding}rem;
	}

	h2 a {
		color: #000;
	}

	.btn,
	button {
		color: white;
	}
`

function renderContent(content: Content) {
	const className = `content content-${content.__typename.replace('node__', '')}`

	return (
		<article className={className}>
			<div className="content-image">
				<Link to={content.path.alias} rel="nofollow">
					<Media source={content.relationships.field_media} />
				</Link>
			</div>
			<div className="content-body">
				<h2>
					<Link to={content.path.alias}>{content.title}</Link>
				</h2>
				{content.fields.summary ? <p>{content.fields.summary}</p> : ''}
			</div>
		</article>
	)
}

const FeaturedContent: ParagraphComponent<'paragraph__featured_content', FeaturedContentParagraph> = ({
	paragraph
}) => (
	<section className="paragraph">
		<StyledContainer className="featured-content">
			{paragraph.relationships.field_reference.map(content => (
				<React.Fragment key={content.drupal_internal__nid}>{renderContent(content)}</React.Fragment>
			))}
		</StyledContainer>
	</section>
)

export default FeaturedContent

export const query = graphql`
	fragment FeaturedContentParagraph on paragraph__featured_content {
		drupal_internal__id
		relationships {
			field_reference {
				__typename
				... on node__article {
					drupal_internal__nid
					title
					path {
						alias
					}
					fields {
						summary
					}
					relationships {
						field_media {
							__typename
							...MediaImageThumbnail
						}
					}
				}
				... on node__recipe {
					drupal_internal__nid
					title
					path {
						alias
					}
					fields {
						summary
					}
					relationships {
						field_media {
							__typename
							...MediaImageThumbnail
						}
					}
				}
			}
		}
	}
`
