import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import { Paragraph, ParagraphComponent } from './types'
import { DrupalProcessedText } from '../../drupal'
import Container from '../Container'

export interface TitleParagraph extends Paragraph {
	field_paragraph_title: DrupalProcessedText
}

const StyledContainer = styled(Container)`
	display: flex;
	align-items: center;
	justify-content: center;
`

const Title: ParagraphComponent<'paragraph__title', TitleParagraph> = ({ paragraph }) => (
	<section className="paragraph">
		<StyledContainer
			dangerouslySetInnerHTML={{ __html: paragraph.field_paragraph_title?.processed ?? '' }}
			className="title"
		/>
	</section>
)

export default Title

export const query = graphql`
	fragment TitleParagraph on paragraph__title {
		drupal_internal__id
		field_paragraph_title {
			processed
		}
	}
`
