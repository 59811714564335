import React from 'react'

import { Paragraph, ParagraphComponents } from './types'

import DebugParagraph from './Debug'
import HeroForm from './HeroForm'
import HeroBanner from './HeroBanner'
import Logos from './Logos'
import Media from './Media'
import MediaGrid from './MediaGrid'
import FeaturedSupporter from './FeaturedSupporter'
import FeaturedContent from './FeaturedContent'
import SignupCount from './SignupCount'
import Title from './Title'
import SpecialEmbed from './SpecialEmbed'
import { CallToAction1Col } from './CallToAction'

export interface Props {
	paragraphs: Paragraph[]
}

const PARAGRAPHS: ParagraphComponents = {
	paragraph__logos: Logos,
	paragraph__hero_form: HeroForm,
	paragraph__hero_banner: HeroBanner,
	paragraph__media: Media,
	paragraph__media_grid: MediaGrid,
	paragraph__call_to_action_single_column: CallToAction1Col,
	paragraph__featured_supporter: FeaturedSupporter,
	paragraph__featured_content: FeaturedContent,
	paragraph__signup_count: SignupCount,
	paragraph__title: Title,
	paragraph__special_embed: SpecialEmbed
}

/**
 * Select paragrah component to render based on __typename parameter.
 *
 * To add new paragraphs you need to:
 *  1. Configure everything on Drupal.
 *  2. Create component and fragment for that paragraph.
 *  3. Add paragraph to `PARAGRAPHS` in this file.
 *  4. Query your paragraph with the fragment in `src/templates/landing_page.tsx`.
 *
 * @param paragraph paragraph data
 */
function renderParagraph(paragraph: Paragraph) {
	if (typeof PARAGRAPHS[paragraph.__typename] !== 'undefined') {
		return React.createElement(PARAGRAPHS[paragraph.__typename], {
			key: paragraph.drupal_internal__id,
			paragraph: paragraph
		})
	}

	// Component doesn't exist yet:
	return <DebugParagraph paragraph={paragraph} />
}

const Paragraphs = ({ paragraphs }: Props) => (
	<>
		{paragraphs.map(paragraph => (
			<React.Fragment key={paragraph.drupal_internal__id}>{renderParagraph(paragraph)}</React.Fragment>
		))}
	</>
)

Paragraphs.defaultProps = {
	extensions: {}
}

export default Paragraphs
