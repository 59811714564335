import { DrupalNode, DrupalMediaImage } from './drupal'

export type Microdata = unknown

/**
 * Get logo url
 */
export function getLogo() {
	return require(`./data/${process.env.GATSBY_SITE_LOGO}`)
}

export function getMetatag(metatags: DrupalNode['metatag_normalized'], attributeKey: string, attributeValue: string) {
	return metatags.find(metatag => metatag.attributes[attributeKey] === attributeValue)
}

/** Find metatag by attribute by key, value pair */
export function getMetatagContent(
	metatags: DrupalNode['metatag_normalized'],
	attributeKey: string,
	attributeValue: string
) {
	return getMetatag(metatags, attributeKey, attributeValue)?.attributes.content
}

/**
 * Get frontend public url.
 */
export function getSiteBaseUrl() {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return process.env.GATSBY_DRUPAL_HOST!.replace('users.', '')
}

export function getImageUrl(fieldMedia?: DrupalMediaImage) {
	const childImageSharp = fieldMedia?.relationships.field_media_image.localFile.childImageSharp
	const imgRelativeUrl = childImageSharp?.fluid?.src || childImageSharp?.fixed?.src

	if (imgRelativeUrl) {
		return `${getSiteBaseUrl()}${imgRelativeUrl}`
	}
}
